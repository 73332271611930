import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosRequestConfig } from 'axios';

import { oprApi } from '~/shared/services/api';
import { clearParams } from '~/shared/utils/clearParams';

import { EmployeeSimple } from '../types';

export type GetEmployeesEligibilityAPIResponse = {
  data: EmployeeSimple[];
  count: number;
  countEligible: number;
  countNotEligible: number;
};

export type GetEmployeesEligibilityFilterParams = {
  employeeBandGroup?: string;
  employeeNameOrId?: string;
  zoneName?: string;
  workCountry?: string;
  hireDate?: string | number | Date;
  eligibilityView?: 'all' | 'eligible' | 'ineligible';
  'to-export'?: boolean;
};

export type GetEmployeesEligibilityParams =
  GetEmployeesEligibilityFilterParams & {
    page?: number;
    limit?: number;
    orderField?: string;
    orderValue?: 'asc' | 'desc';
  };

export const EMPLOYEE_ELIGIBILITY_PER_PAGE = 100;

async function searchEmployees(
  { page, limit, ...params }: GetEmployeesEligibilityParams,
  config: AxiosRequestConfig = {}
) {
  const clearedParams = clearParams(params);

  if (
    clearedParams.eligibilityView &&
    clearedParams.eligibilityView !== 'all'
  ) {
    clearedParams.eligible = String(
      clearedParams.eligibilityView === 'eligible'
    );
  }

  delete clearedParams.eligibilityView;

  const response = await oprApi.get<GetEmployeesEligibilityAPIResponse>(
    'employees/eligibility',
    {
      params: {
        ...clearedParams,
        limit,
        page,
      },
      ...config,
    }
  );

  return response.data;
}

export const GET_EMPLOYEES_ELIGIBILITY_QUERY_KEY = 'useGetEmployeesEligibility';

export function useGetEmployeesEligibility(
  params: GetEmployeesEligibilityParams = {}
) {
  return useQuery({
    queryKey: [GET_EMPLOYEES_ELIGIBILITY_QUERY_KEY, params],
    queryFn: () => searchEmployees(params),
  });
}

type UseGetEmployeesEligibilityReportOptions = {
  onSuccess?: (data: Blob) => void;
  onError?: (error: Error) => void;
};

export function useGetEmployeesEligibilityReport({
  onError,
  onSuccess,
}: UseGetEmployeesEligibilityReportOptions = {}) {
  return useMutation({
    mutationFn: (params: GetEmployeesEligibilityParams) =>
      searchEmployees(
        { ...params, 'to-export': true },
        { responseType: 'blob' }
      ),
    onSuccess(data) {
      onSuccess?.(data as unknown as Blob);
    },
    onError,
  });
}
