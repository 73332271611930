import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { oprApi } from '~/shared/services/api';

import { OprPictureModelView } from './useGetProfilePicture';

type PostProfilePicturePayload = {
  id: string;
  file: Blob;
  filename: string;
};

async function postProfilePicture({
  id,
  file,
  filename,
}: PostProfilePicturePayload) {
  const formData = new FormData();
  formData.append('file', file, filename);

  return oprApi.post(`employees/${id}/profile/picture`, formData);
}
type UsePostProfilePictureOptions = {
  onSuccess?: (data: AxiosResponse<OprPictureModelView>) => void;
  onError?: (error: { message?: string }) => void;
};
export function usePostProfilePicture({
  onSuccess = () => {},
  onError = () => {},
}: UsePostProfilePictureOptions = {}) {
  return useMutation({
    mutationFn: postProfilePicture,
    onSuccess,
    onError,
  });
}
