import * as React from 'react';
// import { AiOutlineCamera } from 'react-icons/ai';
import { useTimeout } from 'react-use';
import { useRouter } from 'next/router';
import { signIn, signOut, useSession } from 'next-auth/react';
import {
  Dropdown,
  Flex,
  MediaMatch,
  Text,
  useResponsiveBreakpoint,
} from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import nprogress from 'nprogress';

import { useAuth } from '~/app/contexts/AuthContext';
// import { useProfilePicture } from '~/app/contexts/ProfilePictureContext';
import type { User } from '~/shared/auth/useUser';
import { Can, ClientOnly, Flags, Icon } from '~/shared/components';
import { SighOut } from '~/shared/components/icons/SignOut';

// import { Workday as WorkdayDialog } from '../../../entities/TalentCard/ui/dialogs';
import { Avatar } from '../Avatar';

import {
  StyledArrow,
  StyledAvatarItem,
  StyledBox,
  StyledContent,
  StyledContext,
  StyledDropdownItem,
  StyledHeaderBox,
} from './styles';

// type WorkdayDialogType = 'picture';

type HeaderProps = { user: User };

export function UserDropdown({ user }: HeaderProps) {
  const { t } = useTranslate('web-app');
  const { data: session } = useSession();
  const router = useRouter();
  const { notifyChangedProxy } = useAuth();
  // const { toggleOpen } = useProfilePicture();
  // * This is necessary for force the component re-render in order to apply the correct button width
  useTimeout(500);
  useResponsiveBreakpoint();

  const userNames = user.name?.split(' ') ?? '';
  const simplifiedName = `${userNames.at(0)} ${userNames.at(-1)}`;

  const dropdownButtonRef = React.useRef<HTMLButtonElement>(null);

  const isProxying = !!session?.user.px;

  async function handleSignOut() {
    try {
      await fetch('/api/destroy-language-cookie');
    } finally {
      signOut({ redirect: false });
    }
  }

  async function handleStopProxy() {
    if (!session) {
      return;
    }

    nprogress.start();
    await signIn('register-proxy', {
      user: JSON.stringify({ ...session.user, px: null }),
    });
    notifyChangedProxy();
  }
  // const [workdayDialogType, setWorkdayDialogType] =
  //   React.useState<WorkdayDialogType | null>(null);

  // const PROFILE_PICTURE_WORKDAY_LINK =
  //   'https://www.myworkday.com/abinbev/d/task/2997$4359.htmld';

  // const workdayItemToInfo: Record<
  //   WorkdayDialogType,
  //   { i18nKey: string; link: string; descriptionI18nKey: string }
  // > = {
  //   picture: {
  //     i18nKey: 'talent_card.profile_picture',
  //     link: PROFILE_PICTURE_WORKDAY_LINK,
  //     descriptionI18nKey: 'talent_card.updated_in_24_hours',
  //   },
  // } as const;
  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <StyledContext ref={dropdownButtonRef}>
          <Flex as="span" align="center" css={{ gap: '$3' }}>
            <ClientOnly placeholder={<StyledHeaderBox />}>
              <Avatar
                name={user.name ?? ''}
                employeeId={user.employeeId ?? ''}
                globalId={user.globalId ?? ''}
              />
            </ClientOnly>
          </Flex>

          <StyledArrow />
        </StyledContext>
      </Dropdown.Trigger>
      <StyledContent align="end">
        <StyledAvatarItem>
          <Avatar
            size="md"
            name={user.name ?? ''}
            employeeId={user.employeeId ?? ''}
            globalId={user.globalId ?? ''}
          />
          <MediaMatch greaterThan="md">
            <StyledBox>
              <Text
                css={{
                  color: 'var(--Colors-Neutral-Text-Dark-900, #191F2E)',
                  fontSize: '$2',
                  fontWeight: '$medium',
                }}
              >
                <Text
                  as="span"
                  css={{ display: 'none', '@lg': { display: 'block' } }}
                >
                  {user.name}
                </Text>
                <Text
                  as="span"
                  css={{ display: 'block', '@lg': { display: 'none' } }}
                >
                  {simplifiedName}
                </Text>
              </Text>
              {user.zone ? (
                <Text
                  css={{
                    color: 'var(--Colors-Neutral-Text-Dark-900, #191F2E)',
                    fontSize: '$1',
                    fontWeight: '$normal',
                  }}
                >
                  {user.zone}
                </Text>
              ) : null}
            </StyledBox>
          </MediaMatch>
        </StyledAvatarItem>
        <Can I="proxy" an="User">
          {() =>
            isProxying ? (
              <StyledDropdownItem onSelect={handleStopProxy}>
                <Icon.StopProxy size={18} />
                <Text as="span" css={{ flex: 1 }}>
                  {t('common.stop_proxy')}
                </Text>
              </StyledDropdownItem>
            ) : (
              <Flags authorizedFlags="proxy">
                <StyledDropdownItem onSelect={() => router.push('/proxy')}>
                  <Icon.Proxy size={18} />
                  <Text
                    as="span"
                    css={{ flex: 1, fontSize: '14px', fontWeight: '400' }}
                  >
                    {t('common.proxy_as_user')}
                  </Text>
                </StyledDropdownItem>
              </Flags>
            )
          }
        </Can>
        {/* {!isProxying && (
            <StyledDropdownItem onSelect={toggleOpen} >
              <AiOutlineCamera size={18} />
              <Text as="span" css={{ flex: 1 }}>
                {t('common.change_profile_picture')}
              </Text>
            </StyledDropdownItem>
          )} */}
        <StyledDropdownItem onSelect={handleSignOut}>
          <SighOut />
          <Text
            as="span"
            css={{ flex: 1, fontSize: '14px', fontWeight: '400' }}
          >
            {t('common.sign_out')}
          </Text>
        </StyledDropdownItem>
      </StyledContent>
    </Dropdown>
  );
}
