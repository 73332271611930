import * as React from 'react';

const uuidRegex =
  '[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}';

const pagesPaths = {
  home: '/home',
  dashboardPersonal: '/dashboard/me',
  dashboardManager: new RegExp(`/dashboard/${uuidRegex}$`),
  eligibilityCriteriaExceptions: '/eligibility-criteria/exceptions',
  eligibilityCriteriaSetup: '/eligibility-criteria/setup',
  managerAssessment: '/manager-assessment',
  managerFeedback: '/manager-feedback',
  roleSetting: '/role-setting',
  translations: '/translations',
  feedbackConfiguration: '/feedback-configuration',
  meetingsList: '/meetings',
  meetingCreate: '/meetings/create',
  meetingDetails: new RegExp(`/meetings/${uuidRegex}$`),
  meetingShapes: new RegExp(`/meetings/${uuidRegex}/shapes`),
  meetingEdit: new RegExp(`/meetings/${uuidRegex}/edit`),
  meetingOverview: new RegExp(`/meetings/${uuidRegex}/overview`),
  meetingOverviewTalentPool: new RegExp(
    `/meetings/${uuidRegex}/overview/talent-pool`
  ),
  proxy: '/proxy',
  talentCardPersonal: '/talent-card/me',
  talentCardManager: new RegExp(`/talent-card/${uuidRegex}$`),
  reportTalentSearch: '/reports/talent-search',
  reportMyOrganization: '/reports/my-organization',
  reportTalentPerformance: '/reports/talent-performance',
  reportMeetingDetails: '/reports/meeting-details',
  documents: '/documents',
  funcManagerAssessmentFreeze: 'func/manager-assessment-freeze',
  dashboardPersonalV2: '/dashboard/v2',
  succession: '/succession',
} as const;

export type PagesPathsKeys = keyof typeof pagesPaths;

export type Flags = Record<
  PagesPathsKeys,
  { active: boolean; skippable: boolean }
>;

type FlagsContextData = {
  flags: Flags;
  skipCheck: boolean;
};

const FlagsContext = React.createContext({} as FlagsContextData);

type FlagsProviderProps = {
  children: React.ReactNode;
  flags?: Partial<Flags>;
  skipCheck?: boolean;
};

export function FlagsProvider({
  children,
  flags,
  skipCheck = false,
}: FlagsProviderProps) {
  const providerValue = React.useMemo(
    () => ({
      flags: {
        ...Object.keys(pagesPaths).reduce((acc, curr) => {
          return {
            ...acc,
            // function flags default as false
            [curr]: { active: !curr.startsWith('func') },
          };
        }, {} as Flags),
        ...flags,
      },
      skipCheck,
    }),
    [flags, skipCheck]
  );

  return (
    <FlagsContext.Provider value={providerValue}>
      {children}
    </FlagsContext.Provider>
  );
}

export function useFlags() {
  const context = React.useContext(FlagsContext);

  if (!context) {
    throw new Error('useFlags must be used within a FlagsProvider');
  }

  return context.flags;
}

type MountFlagsOptions = {
  skipCheck?: boolean;
};

export function mountFlags(options: MountFlagsOptions = {}): Flags {
  let returnFlags = {
    home: {
      active: process.env.PAGE_HOME_ENABLED !== 'false',
      skippable: true,
    },
    dashboardPersonal: {
      active: process.env.PAGE_DASHBOARD_PERSONAL_ENABLED !== 'false',
      skippable: false,
    },
    dashboardManager: {
      active: process.env.PAGE_DASHBOARD_MANAGER_ENABLED !== 'false',
      skippable: false,
    },
    eligibilityCriteriaExceptions: {
      active:
        process.env.PAGE_ELIGIBILITY_CRITERIA_EXCEPTIONS_ENABLED !== 'false',
      skippable: true,
    },
    eligibilityCriteriaSetup: {
      active: process.env.PAGE_ELIGIBILITY_CRITERIA_SETUP_ENABLED !== 'false',
      skippable: true,
    },
    managerAssessment: {
      active: process.env.PAGE_MANAGER_ASSESSMENT_ENABLED !== 'false',
      skippable: true,
    },
    managerFeedback: {
      active: process.env.PAGE_MANAGER_FEEDBACK_ENABLED !== 'false',
      skippable: true,
    },
    roleSetting: {
      active: process.env.PAGE_ROLE_SETTING_ENABLED !== 'false',
      skippable: true,
    },
    translations: {
      active: process.env.PAGE_TRANSLATIONS_ENABLED !== 'false',
      skippable: true,
    },
    meetingsList: {
      active: process.env.PAGE_MEETINGS_LIST_ENABLED !== 'false',
      skippable: true,
    },
    meetingCreate: {
      active: process.env.PAGE_MEETING_CREATE_ENABLED !== 'false',
      skippable: true,
    },
    meetingDetails: {
      active: process.env.PAGE_MEETING_DETAILS_ENABLED !== 'false',
      skippable: true,
    },
    meetingShapes: {
      active: process.env.PAGE_MEETING_SHAPES_ENABLED !== 'false',
      skippable: true,
    },
    meetingEdit: {
      active: process.env.PAGE_MEETING_EDIT_ENABLED !== 'false',
      skippable: true,
    },
    meetingOverview: {
      active: process.env.PAGE_MEETING_OVERVIEW_ENABLED !== 'false',
      skippable: true,
    },
    meetingOverviewTalentPool: {
      active: process.env.PAGE_MEETING_OVERVIEW_TALENT_POOL_ENABLED !== 'false',
      skippable: true,
    },
    proxy: {
      active: process.env.PAGE_PROXY_ENABLED !== 'false',
      skippable: true,
    },
    talentCardPersonal: {
      active: process.env.PAGE_TALENT_CARD_PERSONAL_ENABLED !== 'false',
      skippable: true,
    },
    talentCardManager: {
      active: process.env.PAGE_TALENT_CARD_MANAGER_ENABLED !== 'false',
      skippable: true,
    },
    reportTalentSearch: {
      active: process.env.PAGE_REPORT_TALENT_SEARCH_ENABLED !== 'false',
      skippable: true,
    },
    reportMyOrganization: {
      active: process.env.PAGE_REPORT_MY_ORGANIZATION_ENABLED !== 'false',
      skippable: true,
    },
    reportTalentPerformance: {
      active: process.env.PAGE_REPORT_TALENT_PERFORMANCE_ENABLED !== 'false',
      skippable: true,
    },
    reportMeetingDetails: {
      active: process.env.PAGE_REPORT_MEETING_DETAILS_ENABLED !== 'false',
      skippable: true,
    },
    documents: {
      active: process.env.PAGE_DOCUMENTS_ENABLED !== 'false',
      skippable: true,
    },
    feedbackConfiguration: {
      active: process.env.FEEDBACK_CONFIGURATION_ENABLED !== 'false',
      skippable: true,
    },
    funcManagerAssessmentFreeze: {
      active: process.env.FUNC_MANAGER_ASSESSMENT_PROCESS_FREEZE === 'true',
      skippable: true,
    },
    dashboardPersonalV2: {
      active: process.env.PAGE_DASHBOARD_V2_PERSONAL_ENABLED !== 'false',
      skippable: false,
    },
    succession: {
      active: process.env.PAGE_SUCCESSION !== 'false',
      skippable: false,
    },
  };

  // don't skip 'function' flags
  if (options.skipCheck) {
    returnFlags = {
      ...returnFlags,
      ...(Object.keys(pagesPaths)
        .filter(_i => {
          const key = _i as keyof typeof returnFlags;

          if (!returnFlags[key].skippable) {
            return false;
          }

          return !key.startsWith('func');
        })
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: { active: true },
          }),
          {}
        ) as Flags),
    };
  }

  return returnFlags;
}

type ShouldBlockAccessOptions = {
  skipCheck?: boolean;
};

export function shouldBlockAccess(
  path: string,
  options: ShouldBlockAccessOptions = {}
) {
  if (options.skipCheck) {
    return false;
  }

  const flags = mountFlags({ skipCheck: options.skipCheck });

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(pagesPaths)) {
    if (typeof value === 'string' && path === value) {
      return !flags[key as PagesPathsKeys].active;
    }

    if (value instanceof RegExp && value.test(path)) {
      return !flags[key as PagesPathsKeys].active;
    }
  }

  return false;
}
