import { RxEnterFullScreen } from 'react-icons/rx';
import { Button } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useFullscreen } from '~/app/contexts/FullscreenContext';

export function FullScreenActivate({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslate('web-app');

  const fullscreen = useFullscreen();

  return (
    <Button
      leftIcon={<RxEnterFullScreen />}
      variant="secondary"
      onClick={fullscreen.activate}
      title={t('actions.activate', { t: t('common.fullscreen') })}
      disabled={disabled}
      // TODO: UX Remove after DS update
      css={{
        height: '$10',
        border: '1px solid #CACDD5',
        boxShadow: '0px 1px 1px 0px #00000014',
        color: '#325A6D',
      }}
    >
      {t('common.fullscreen')}
    </Button>
  );
}
