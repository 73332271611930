// TODO: UX - Create as a shared resource on DS lib
import { Label, styled } from '@ghq-abi/design-system';

export const FilterLabel = styled(Label, {
  fontWeight: '$bold !important',
  fontSize: '$xs !important',
  color: '#3F465A !important',
});

export const FilterWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$xs',
  flex: 1,
  minWidth: '$72',
  whiteSpace: 'nowrap',
  flexBasis: 'calc(100% - $4)',

  '@lg': {
    flexBasis: 'calc(50% - $4)',
  },

  '@xl': {
    flexBasis: 'calc(20% - $4)',
  },
});
