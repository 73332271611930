import * as React from 'react';
import { FiX } from 'react-icons/fi';
import { Flex, IconButton, theme } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { Filter as FilterIcon } from '../icons';

type FilterButtonProps = {
  isShowingFilters: boolean;
  disabled: boolean;
  onClick: () => void;
};

export function FilterButton({
  isShowingFilters,
  disabled,
  onClick,
}: FilterButtonProps) {
  const id = React.useId();

  const { t } = useTranslate('web-app');

  return (
    <IconButton
      type="button"
      title={`${isShowingFilters ? t('common.hide') : t('common.show')} ${t(
        'common.filters'
      )}`}
      css={{
        height: '$10',
        width: '$10',
        border: '1px solid #CACDD5',
        '&:hover:not(:disabled), &:focus:not(:disabled)': {
          [`#${id}`]: {
            backgroundColor: `${theme.colors.gray100} !important`,
          },
        },
        '&:active:not(:disabled)': {
          [`#${id}`]: {
            backgroundColor: `${theme.colors.gray200} !important`,
          },
        },
      }}
      icon={
        <Flex css={{ position: 'relative', overflow: 'hidden' }}>
          <FilterIcon />
          <FiX
            id={id}
            style={{
              position: 'absolute',
              backgroundColor: theme.colors.white.toString(),
              transition: theme.transitions['promptly-ease'].toString(),
              willChange: 'transform',
              borderRadius: '50%',
              transform: `${
                isShowingFilters
                  ? 'translateX(0) scale(0.7)'
                  : 'translateX(200%) scale(0)'
              }`,
              right: '-5px',
            }}
          />
        </Flex>
      }
      onClick={onClick}
      disabled={disabled}
    />
  );
}
