import * as React from 'react';
import Link from 'next/link';
import { CSS, Dropdown, Flex, styled, Text } from '@ghq-abi/design-system';

type ModulesDropdownItemProps = {
  url?: string;
  icon?: React.ReactNode;
  label: string;
  subtitle: string;
  onSelect?: () => void;
};

const itemCSS: CSS = {
  p: '$md',
  '&:focus:not(:hover)': {
    boxShadow: '0 0 0 2px $colors$black inset',
  },
};
const StyledText = styled(Text, {
  fontWeight: '$normal',
  lineHeight: '160%',
  color: '#7D8597',
  fontSize: '$0-5',
});

const StyledLink = styled(Link, {
  display: 'flex',
  gap: '$md',
  padding: '$2 $4 !important',
  borderRadius: '4px',
});

const SytledLabels = styled(Text, {
  display: 'flex',
  lineHeight: '160%',
  flexDirection: 'column',
});

export function ModulesDropdownItem({
  url,
  icon,
  label,
  subtitle,
  onSelect,
}: ModulesDropdownItemProps) {
  const isLocalUrl = url?.startsWith('/');

  return (
    <Dropdown.Item onSelect={onSelect} asChild css={itemCSS}>
      {!isLocalUrl ? (
        <Flex
          align="center"
          gap="md"
          as="a"
          href={url}
          target="_self"
          css={{
            padding: '$2 $4 !important',
            borderRadius: '4px',
          }}
        >
          {icon}
          <SytledLabels>
            <Text css={{ fontWeight: '$normal' }}>{label}</Text>
            <StyledText css={{ fontWeight: '$normal' }}>{subtitle}</StyledText>
          </SytledLabels>
        </Flex>
      ) : (
        <StyledLink href={url ?? ''}>
          {icon}
          <SytledLabels>
            <Text css={{ fontWeight: '$normal' }}>{label}</Text>
            <StyledText css={{ fontWeight: '$normal' }}>{subtitle}</StyledText>
          </SytledLabels>
        </StyledLink>
      )}
    </Dropdown.Item>
  );
}
