import React from 'react';
import { BsTranslate } from 'react-icons/bs';
import { signIn, useSession } from 'next-auth/react';
import { Button, Dropdown, reusables } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { LanguagesEnum } from 'abi-opr-common-types';

import { useToast } from '~/app/contexts/ToastContext';
import { useUpdatePreferredLanguage } from '~/entities/Translations';
import { CredentialProviders } from '~/shared/auth/types/providers';

type LanguageDropdownProps = {
  currentLanguage: keyof typeof LanguagesEnum;
};

export const LanguageDropdown = ({
  currentLanguage,
}: LanguageDropdownProps) => {
  const { data: session } = useSession();
  const { t } = useTranslate('web-app');
  const toast = useToast();
  const userLanguage = currentLanguage?.replaceAll(
    '_',
    '-'
  ) as keyof typeof LanguagesEnum;

  const updatePreferredLanguage = useUpdatePreferredLanguage({
    onSuccess({ data }) {
      toast.add({
        description: t('translations.preferred_language.success'),
        type: 'success',
      });

      const currentUser = session?.user;
      if (currentUser === undefined) return;

      currentUser.lcl = data.oprPreferredLanguage;

      signIn(CredentialProviders.UPDATE_LANGUAGE, {
        user: JSON.stringify(currentUser),
      });
    },
    onError() {
      toast.add({
        description: t('translations.preferred_language.error'),
        type: 'error',
      });
    },
  });

  function handleLanguageChange(value: string) {
    toast.add({
      description: t('translations.preferred_language.updating'),
      type: 'neutral',
    });
    updatePreferredLanguage.mutate({ preferredLanguage: value });
  }
  return (
    <Dropdown>
      <Dropdown.Trigger asChild disabled={updatePreferredLanguage.isLoading}>
        <Button
          css={{ boxShadow: 'none', padding: '$2' }}
          variant="secondary"
          rightIcon={<BsTranslate size={16} color="#000" />}
        >
          {t(`languages.${userLanguage}`)}
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        className={reusables.scrollbar()}
        css={{
          height: '$md',
          overflow: 'auto',
          zIndex: '$overlay',
          boxShadow: '8px 0px 16px 0px #191F2E3D',
        }}
      >
        {Object.keys(LanguagesEnum).map(lang => (
          <Dropdown.Item onSelect={() => handleLanguageChange(lang)} key={lang}>
            {t(`languages.${lang}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
};
