import * as React from 'react';
import { Avatar as DSAvatar, Skeleton } from '@ghq-abi/design-system';

import { useUser } from '~/shared/auth/useUser';

import { useGetProfilePicture } from '../../../entities/Employee/api/useGetProfilePicture';

type AvatarProps = React.ComponentProps<typeof DSAvatar> & {
  employeeId?: string;
  globalId?: string;
};

export function Avatar({ employeeId, globalId, ...rest }: AvatarProps) {
  const wdPicture = `${process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL}/${globalId}`;
  const user = useUser();
  const isUserAvatar = user.employeeId === employeeId;
  const { data, isError } = useGetProfilePicture(
    employeeId ?? '',
    isUserAvatar
  );
  const imageSrc = data?.id ? data.url : wdPicture;

  return data || (!data && isError && isUserAvatar) || !isUserAvatar ? (
    <DSAvatar src={imageSrc} {...rest} />
  ) : (
    <Skeleton variant="circle" size={32} />
  );
}
