import * as React from 'react';
import {
  Box,
  Button,
  type CropperRef,
  Dialog,
  DragAndDrop,
  type DragAndDropFilesList,
  DragAndDropForwardedRef,
  Flex,
  ImageCropper,
} from '@ghq-abi/design-system';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';

import { useToast } from '~/app/contexts/ToastContext';
import { randomUUID } from '~/shared/utils/randomUUID';

import {
  GET_PROFILE_PICTURE_QUERY_KEY,
  usePostProfilePicture,
} from '../../api';

type EditProfilePictureDialogProps = {
  employeeId: string;
  isOpen: boolean;
  toggleOpen: () => void;
};

const IMAGE_SIZE_LIMIT_IN_MB = 2;

export const EditProfilePictureDialog = ({
  employeeId,
  isOpen,
  toggleOpen,
}: EditProfilePictureDialogProps) => {
  const { t } = useTranslate('web-app');
  const toast = useToast();
  const queryClient = useQueryClient();
  const imageCropperRef = React.useRef<CropperRef>();
  const dropZoneRef = React.useRef<DragAndDropForwardedRef>(null);
  const [picture, setPicture] = React.useState<{
    img: string;
    type: string;
    originalExtension: string;
  } | null>(null);

  const postProfilePictureMutation = usePostProfilePicture({
    onSuccess() {
      toast.add({
        title: t('profile_picture_modal.profile_picture_success_title'),
        description: t('profile_picture_modal.profile_picture_success'),
        type: 'success',
      });
      queryClient.removeQueries([GET_PROFILE_PICTURE_QUERY_KEY, employeeId]);
      setPicture(null);
      toggleOpen();
    },
    onError() {
      toast.add({
        title: t('profile_picture_modal.profile_picture_error_title'),
        description: t('profile_picture_modal.profile_picture_error'),
        type: 'error',
      });
    },
  });

  const savePictureBlobData = (blob: Blob | null) => {
    if (!blob || !picture) return;
    const { type, originalExtension } = picture;
    const file = new File(
      [blob],
      `${employeeId}-picture.${originalExtension}`,
      { type }
    );

    const imageSizeInMB = parseFloat((file.size / 1000 / 1000).toFixed(2));

    if (imageSizeInMB > IMAGE_SIZE_LIMIT_IN_MB) {
      toast.add({
        title: t('profile_picture_modal.profile_picture_size_error_title'),
        description: t('profile_picture_modal.profile_picture_size_error', {
          s: `${IMAGE_SIZE_LIMIT_IN_MB}MB`,
        }),
        type: 'error',
      });
      return;
    }

    postProfilePictureMutation.mutate({
      id: employeeId,
      file,
      filename: `${employeeId}-picture-${randomUUID()}.${originalExtension}`,
    });

    toast.add({
      title: t('profile_picture_modal.profile_picture_warning_title'),
      description: t('profile_picture_modal.profile_picture_warning'),
      type: 'warning',
    });
  };

  const handleSavePicture = async () => {
    if (!picture) return;
    imageCropperRef.current
      ?.getCanvas()
      ?.toBlob(savePictureBlobData, picture.type, 0.9);
  };

  const handleResetPicturePosition = () => imageCropperRef.current?.reset();
  const handleResetImage = () => setPicture(null);
  const handleOnClose = () => {
    if (postProfilePictureMutation.isLoading) return;
    setPicture(null);
    toggleOpen();
  };

  const handleFileChange = async (fileList: DragAndDropFilesList) => {
    if (!fileList) return;
    const file = fileList[0];
    if (file.type.split('/')[0] !== 'image') {
      toast.add({
        title: t('profile_picture_modal.profile_picture_type_error_title'),
        description: t('profile_picture_modal.profile_picture_type_error'),
        type: 'error',
      });
      dropZoneRef.current?.clearInput();
      return;
    }
    if (file.size > 1000000) {
      toast.add({
        title: t('profile_picture_modal.profile_picture_too_large_title'),
        description: t(
          'profile_picture_modal.profile_picture_too_large_description'
        ),
        type: 'error',
      });
      dropZoneRef.current?.clearInput();
      return;
    }
    const img = URL.createObjectURL(file);
    try {
      const result = await fetch(img);
      const blob = await result.blob();
      let originalExtension = file.name.split('.').pop() ?? 'jpg';
      if ('name' in blob && typeof blob.name === 'string') {
        originalExtension = blob.name.split('.').pop() ?? 'jpg';
      }
      setPicture({ img, type: blob.type, originalExtension });
    } catch (err) {
      console.error({ err });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOnClose}>
      <Dialog.Content
        css={{
          maxWidth: '$xl',
          overflow: 'auto',
        }}
      >
        <Flex direction="column" align="center" gap="sm">
          <Dialog.Close asChild onClick={handleOnClose}>
            <Dialog.CloseIcon />
          </Dialog.Close>
          <Dialog.Title css={{ mb: '$sm' }}>
            {t('profile_picture_modal.title')}
          </Dialog.Title>
          {picture ? (
            <Flex direction="column" gap="lg">
              <Box
                css={{
                  width: '400px',
                  height: '400px',
                  '.rmc-cropper-wrapper': {
                    color: '#fff',
                    backgroundColor: '#fff',
                  },
                  pointerEvents: postProfilePictureMutation.isLoading
                    ? 'none'
                    : 'auto',
                  opacity: postProfilePictureMutation.isLoading ? 0.5 : 1,
                }}
              >
                <ImageCropper
                  ref={imageCropperRef}
                  src={picture.img}
                  backgroundClassName="background"
                />
              </Box>
              <Flex
                gap="md"
                direction="column"
                css={{ minWidth: 'calc($full - 100px)' }}
              >
                <Button
                  disabled={postProfilePictureMutation.isLoading}
                  onClick={handleSavePicture}
                >
                  {t('common.save')}
                </Button>
                <Flex gap="md" css={{ width: '$full' }}>
                  <Button
                    disabled={postProfilePictureMutation.isLoading}
                    full
                    variant="secondary"
                    onClick={handleResetPicturePosition}
                  >
                    {t('common.reset_picture_position')}
                  </Button>
                  <Button
                    disabled={postProfilePictureMutation.isLoading}
                    full
                    variant="secondary"
                    onClick={handleResetImage}
                  >
                    {t('common.return')}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <DragAndDrop
              ref={dropZoneRef}
              label={t('profile_picture_modal.drag_drop_label')}
              buttonLabel={t('profile_picture_modal.upload_button')}
              onDrop={e => e.preventDefault()}
              onDragOver={e => e.preventDefault()}
              onFileChange={handleFileChange}
              inputProps={{ multiple: false, accept: 'image/*' }}
            />
          )}
        </Flex>
      </Dialog.Content>
    </Dialog>
  );
};
