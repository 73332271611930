import { useQuery } from '@tanstack/react-query';

import { oprApi } from '~/shared/services/api';

export type OprPictureModelView = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  url: string;
  origin: string;
  status: string;
  module: string;
};

export const GET_PROFILE_PICTURE_QUERY_KEY = 'useGetProfilePicture';

async function getProfilePicture(employeeId?: string) {
  if (!employeeId) return null;
  const response = await oprApi.get<OprPictureModelView>(
    `employees/${employeeId}/profile/picture`
  );
  return response;
}

export function useGetProfilePicture(employeeId: string, enabled = true) {
  return useQuery({
    queryKey: [GET_PROFILE_PICTURE_QUERY_KEY, employeeId],
    queryFn: () => getProfilePicture(employeeId),
    select: data => data?.data,
    enabled,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
