import * as React from 'react';
import Link from 'next/link';
import { CSS, Flex, MediaMatch, Skeleton, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { Avatar } from '../../../app/components/Avatar';
import { Flags } from '../Flags';
import * as Icon from '../icons';

type EmployeeProfileResumeProps = {
  employeeId?: string;
  globalId?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  hasDashboard?: boolean;
  isLoading?: boolean;
  css?: CSS;
};

// TODO: Adjust to use on manager assessment user profile too

export function EmployeeProfileResume({
  employeeId,
  globalId,
  firstName,
  lastName,
  position,
  hasDashboard,
  isLoading,
  css,
}: EmployeeProfileResumeProps) {
  const { t } = useTranslate('web-app');
  const name = firstName && lastName ? `${firstName} ${lastName}` : '';

  return (
    <Flex justify="start" css={css} align="center" gap="sm">
      <MediaMatch greaterThan="lg">
        {!isLoading && globalId && (
          <Avatar
            size="md"
            name={name}
            employeeId={employeeId}
            globalId={globalId}
            key={employeeId}
          />
        )}
        {isLoading && <Skeleton variant="circle" />}
      </MediaMatch>

      {isLoading && (
        <>
          <Flex gap="sm" align="center">
            <Skeleton width={20} height={28} />
            <Skeleton width={20} height={28} />
          </Flex>

          <Flex direction="column" gap="sm" justify="start" align="start">
            <Skeleton variant="line" width={180} height={22} />
            <Skeleton variant="line" width={90} height={16} />
          </Flex>
        </>
      )}

      {!isLoading && (
        <>
          <Flex gap="sm" align="center" justify="center" css={{ mt: '$xs' }}>
            <Flags authorizedFlags="talentCardManager">
              <Link
                href={`/talent-card/${employeeId}`}
                target="_blank"
                rel="noopener noreferrer"
                title={t('common.view_talent_card')}
              >
                <Icon.Profile />
              </Link>
            </Flags>
            <Flags authorizedFlags="dashboardManager">
              {hasDashboard && (
                <Link
                  href={`/dashboard/v2/${employeeId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={t('actions.view', {
                    t: t('common.dashboard'),
                  })}
                >
                  <Icon.Chart />
                </Link>
              )}
            </Flags>
          </Flex>

          <Flex
            gap="xs"
            direction="column"
            justify="start"
            align="start"
            css={{ ml: '$2-5' }}
          >
            <Text
              as="h3"
              css={{
                fontSize: '$md',
                lineHeight: '$5',
                fontWeight: '$medium',
                textTransform: 'uppercase',
              }}
            >
              {firstName && lastName ? (
                <>
                  {firstName} {lastName}
                </>
              ) : (
                name
              )}
            </Text>
            {position && (
              <Text
                as="span"
                css={{
                  mt: '$2',
                  display: 'block',
                  fontWeight: '$normal',
                  color: '#2E2C34',
                  fontSize: '$sm',
                  lineHeight: '$5',
                }}
              >
                {position}
              </Text>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
}
