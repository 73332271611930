import type { Session } from 'next-auth';
import {
  AbilityBuilder,
  AbilityClass,
  ConditionsMatcher,
  mongoQueryMatcher,
  PureAbility,
} from '@casl/ability';
import { ModuleEnum } from 'abi-opr-common-types';

import type { GetMeetingSelectData } from '~/entities/Meeting/api/useGetMeeting';

import { rankBand } from '../utils/rankBand';

enum Action {
  List = 'list',
  Create = 'create',
  View = 'view',
  Update = 'update',
  Manage = 'manage',
  Vote = 'vote',
  Facilitate = 'facilitate',
  Proxy = 'proxy',
  Unlock = 'unlock',
  Skip = 'skip',
  Delete = 'delete',
}

export type Entities =
  | 'Meeting'
  | 'User'
  | 'EligibilityCriteriaSetup'
  | 'EligibilityCriteriaExceptions'
  | 'RoleSetting'
  | 'Translations'
  | 'Home'
  | 'PersonalDashboard'
  | 'PersonalTalentCard'
  | 'ManagerDashboard'
  | 'ManagerTalentCard'
  | 'ManagerAssessment'
  | 'ManagerFeedback'
  | 'HelperFooter'
  | 'ReportTalentSearch'
  | 'ReportMyOrganization'
  | 'ReportTalentPerformance'
  | 'ReportMeetingDetails'
  | 'Documents'
  | 'FeatureFlags'
  | 'Cheershub'
  | 'Rewards'
  | 'Compensation'
  | 'PersonalDashboardV2'
  | 'MyLCM'
  | 'Stellar'
  | 'Succession'
  | `${ModuleEnum}`;

export type AbilityTuple = [`${Action}`, Entities | Record<string, unknown>];

export type AppAbility = PureAbility<AbilityTuple>;

// const TEMPORARY_PERMISSION_TO_CREATE_MEETING_IDS = [
//   '29023629',
//   '29035209',
//   '29035114',
//   '08306708',
//   '12029166',
// eslint-disable-next-line no-irregular-whitespace
//   '10076218​',
// ];

// const ZTA_TEMPORARY_PERMISSION_TO_CREATE_MEETING_IDS = [
//   '29023629',
//   '29035114',
//   '29035209',
//   '29034292',
// ];

type Subjects = {
  meeting?: Pick<
    GetMeetingSelectData,
    'id' | 'isVoter' | 'isFacilitator' | 'isOwner' | 'zone'
  >;
  talentCard?: { allowAccess: boolean };
  dashboard?: { allowAccess: boolean };
};

const allowedZonesToApps = {
  cheershub: [
    'MAZ',
    'SAZ',
    'GHQ',
    'GLO',
    'NO-ZONE',
    'GLOBAL',
    'ZONE MIDDLE AMERICAS',
    'ZONE SOUTH AMERICA',
  ],
  rewards: [
    'EUR',
    'SAZ',
    'GHQ',
    'GLO',
    'NO-ZONE',
    'GLOBAL',
    'ZONE SOUTH AMERICA',
    'ZONE EUROPE',
  ],
  compensation: [
    'AFR',
    'MAZ',
    'NAZ',
    'EUR',
    'ZONE MIDDLE AMERICAS',
    'ZONE EUROPE',
    'APAC',
    'GHQ',
    'GLOBAL',
  ],
};

export function createUserAbility(
  user?: Session['user'],
  permissions: Subjects = {},
  rules?: AppAbility['_indexedRules']
) {
  const { can, build } = new AbilityBuilder(
    PureAbility as AbilityClass<AppAbility>
  );

  const originalUserRoles =
    user?.rols ?? ({} as Exclude<Session['user']['rols'], null>);
  const userRoles =
    user?.px?.rols ??
    user?.rols ??
    ({} as Exclude<Session['user']['rols'], null>);
  const userManagedZones = user?.px?.zones ?? user?.zones ?? [];

  const userAbilities = user?.px?.ablty ?? user?.ablty ?? [];

  const isManager = user?.px?.rols.isManager ?? user?.rols?.isManager ?? false;
  const isEligible = user?.px?.elg ?? user?.elg;
  const has10x = user?.px?.x10 ?? user?.x10;
  const isVoter = user?.px?.vtr ?? user?.vtr;

  // Map user Abilities
  userAbilities.forEach(ability => {
    can(ability.action, ability.module);
  });

  can(Action.View, 'PersonalTalentCard');
  can(Action.View, 'Documents');

  if (isEligible) {
    can(Action.View, 'Home');
  }

  if (
    allowedZonesToApps.cheershub.includes((user?.px?.zone || user?.zone) ?? '')
  ) {
    can(Action.View, 'Cheershub');
  }

  if (
    allowedZonesToApps.rewards.includes((user?.px?.zone || user?.zone) ?? '') &&
    rankBand((user?.px?.bandg || user?.bandg) ?? '') <= 4
  ) {
    can(Action.View, 'Rewards');
  }

  can(Action.View, 'Compensation');

  if (rankBand((user?.px?.bandg || user?.bandg) ?? '') <= 7) {
    can(Action.View, 'MyLCM');
  }

  if (originalUserRoles.isSuperAdmin || originalUserRoles.isGlobalAdmin) {
    can(Action.Proxy, 'User');
  }

  if (has10x && isEligible) {
    can(Action.View, 'PersonalDashboard');
  }

  if (isVoter) {
    can(Action.List, 'Meeting');
  }

  if (has10x) {
    can(Action.View, 'PersonalDashboardV2');
  }

  // Manager roles
  if (isManager) {
    can(Action.View, [
      'Home',
      'ManagerAssessment',
      ModuleEnum.GetEmployeeFeedbackDetailModelView,
      'ReportTalentSearch',
      'ReportTalentPerformance',
      'ReportMyOrganization',
    ]);
    can(Action.List, ModuleEnum.GetEmployeeFeedbackModelView);
    can(Action.Update, ModuleEnum.Feedback);
  }

  // Succession Access
  if (userRoles.isSuccessionAccess && userRoles.isGlobalAdmin) {
    can(Action.View, 'Succession');
    can(Action.Unlock, 'Succession');
    can(Action.Update, 'Succession');
  }

  if (userRoles.isSuccessionAccess && userRoles.isPBP) {
    can(Action.View, 'Succession');
  }

  if (userRoles.isSuccessionAccess && userRoles.isZoneAdmin) {
    can(Action.View, 'Succession');
  }

  // Super Admin
  if (userRoles.isSuperAdmin) {
    can(Action.Create, [ModuleEnum.FeedbackConfiguration, 'Meeting']);
    can(Action.View, [
      'Home',
      ModuleEnum.GetEmployeeFeedbackDetailModelView,
      'HelperFooter',
      'ReportTalentSearch',
      'ReportTalentPerformance',
      'ReportMeetingDetails',
      'ReportMyOrganization',
      'ManagerAssessment',
      'EligibilityCriteriaSetup',
      'Meeting',
      'EligibilityCriteriaExceptions',
    ]);
    can(Action.Update, ModuleEnum.Feedback);
    can(Action.List, [
      'Meeting',
      ModuleEnum.GetEmployeeFeedbackModelView,
      ModuleEnum.FeedbackConfiguration,
    ]);
    can(Action.Manage, ['Translations', 'RoleSetting']);
    can(Action.Unlock, 'ManagerAssessment');
    can(Action.Skip, 'FeatureFlags');
    can(Action.Delete, ['Meeting', ModuleEnum.FeedbackConfiguration]);
    can(Action.Facilitate, 'Meeting');
    can(Action.Update, 'EligibilityCriteriaSetup');
  }

  // Global Admin
  if (userRoles.isGlobalAdmin) {
    can(Action.Create, [ModuleEnum.FeedbackConfiguration, 'Meeting']);
    can(Action.Update, ModuleEnum.Feedback);
    can(Action.List, [
      'Meeting',
      ModuleEnum.GetEmployeeFeedbackModelView,
      ModuleEnum.FeedbackConfiguration,
    ]);
    can(Action.View, [
      'Home',
      ModuleEnum.GetEmployeeFeedbackDetailModelView,
      'HelperFooter',
      'ReportTalentSearch',
      'ReportTalentPerformance',
      'ReportMeetingDetails',
      'ReportMyOrganization',
      'ManagerAssessment',
      'EligibilityCriteriaSetup',
      'Meeting',
      'EligibilityCriteriaExceptions',
    ]);
    can(Action.Update, 'EligibilityCriteriaSetup');
    can(Action.Manage, ['Translations', 'RoleSetting']);
    can(Action.Unlock, 'ManagerAssessment');
    can(Action.Skip, 'FeatureFlags');
    can(Action.Delete, ['Meeting', ModuleEnum.FeedbackConfiguration]);
    can(Action.Facilitate, 'Meeting');
    can(Action.View, 'Stellar');
  }

  // Zone Admin
  if (userRoles.isZoneAdmin) {
    // if (
    //   process.env.NEXT_PUBLIC_BLOCK_CREATE_MEETINGS === 'false' ||
    //   (user?.gid &&
    //     ZTA_TEMPORARY_PERMISSION_TO_CREATE_MEETING_IDS.includes(
    //       user?.px?.gid ?? user?.gid
    //     ))
    // ) {
    //   can(Action.Create, 'Meeting');
    // }

    can(Action.Update, ModuleEnum.Feedback);
    can(Action.List, ['Meeting', ModuleEnum.GetEmployeeFeedbackModelView]);
    can(Action.View, [
      'HelperFooter',
      'ReportTalentSearch',
      'ReportTalentPerformance',
      'ReportMeetingDetails',
      'ReportMyOrganization',
      'ManagerAssessment',
      'Home',
      'EligibilityCriteriaExceptions',
      ModuleEnum.GetEmployeeFeedbackDetailModelView,
    ]);

    can(Action.Skip, 'FeatureFlags');

    if (userManagedZones.includes(permissions.meeting?.zone ?? '')) {
      can([Action.View, Action.Delete, Action.Facilitate], 'Meeting');
    }
  }

  // PBP
  if (userRoles.isPBP) {
    if (process.env.NEXT_PUBLIC_BLOCK_CREATE_MEETINGS === 'false') {
      can(Action.Create, 'Meeting');
    }
    can(Action.Update, ModuleEnum.Feedback);
    can(Action.View, [
      'Home',
      'ManagerAssessment',
      ModuleEnum.GetEmployeeFeedbackDetailModelView,
    ]);
    can(Action.List, [ModuleEnum.GetEmployeeFeedbackModelView, 'Meeting']);
    can(Action.View, [
      'Meeting',
      'HelperFooter',
      'ReportTalentSearch',
      'ReportTalentPerformance',
      'ReportMeetingDetails',
      'ReportMyOrganization',
    ]);

    can(Action.Delete, 'Meeting');
    can(Action.Skip, 'FeatureFlags');
    can(Action.View, 'Stellar');
  }

  // Talent Manager
  if (userRoles.isTalentManager) {
    can(Action.View, 'ReportMeetingDetails');
  }

  // if (
  //   (user?.gid &&
  //     TEMPORARY_PERMISSION_TO_CREATE_MEETING_IDS.includes(
  //       user?.px?.gid ?? user?.gid
  //     )) ||
  //   (user?.uid &&
  //     TEMPORARY_PERMISSION_TO_CREATE_MEETING_IDS.includes(
  //       user?.px?.uid ?? user?.uid
  //     ))
  // ) {
  //   can(Action.Create, 'Meeting');
  // }

  if (permissions.talentCard?.allowAccess) {
    can(Action.View, 'ManagerTalentCard');
  }

  if (permissions.dashboard?.allowAccess) {
    can(Action.View, 'ManagerDashboard');
  }

  if (permissions.meeting) {
    if (permissions.meeting.isVoter) {
      can(Action.View, 'Meeting', { id: permissions.meeting.id });
      can(Action.Vote, 'Meeting', { id: permissions.meeting.id });
    }

    if (permissions.meeting.isFacilitator || permissions.meeting.isOwner) {
      can(Action.View, 'Meeting', { id: permissions.meeting.id });
      can(Action.Facilitate, 'Meeting', { id: permissions.meeting.id });
    }

    if (permissions.meeting.isOwner) {
      can(Action.Delete, 'Meeting', { id: permissions.meeting.id });
    }
  }

  const ability = build({
    // eslint-disable-next-line no-underscore-dangle
    detectSubjectType: (object: Record<string, 'Meeting'>) => object.__typename,
    conditionsMatcher: mongoQueryMatcher as ConditionsMatcher<unknown>,
  });

  if (rules) {
    ability.update(rules);
  }

  return ability;
}
