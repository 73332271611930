import * as React from 'react';
import { Dropdown, Flex, IconButton, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { ActionEnum } from 'abi-opr-common-types';

import { Can, Icon } from '~/shared/components';

import { ModulesDropdownItem } from './ModulesDropdownItem';

export function ModulesDropdown() {
  const { t } = useTranslate('web-app');
  const [activated, setActivated] = React.useState(false);

  function handleOpenChange(open: boolean) {
    setActivated(open);
  }

  return (
    <Dropdown onOpenChange={handleOpenChange} open={activated}>
      <Dropdown.Trigger asChild>
        <IconButton
          css={{
            svg: { width: '$4', height: '$4' },
            padding: '$1-5',
            '&:hover:not(:disabled)': {
              backgroundColor: '#191F2E14',
              borderRadius: '100px',
            },
            borderRadius: activated ? '100px' : '0px',
            border: 'none',
          }}
          title={t('menu.trigger_modules_dropdown')}
          icon={<Icon.Modules color="#171717" />}
        />
      </Dropdown.Trigger>
      <Dropdown.Content
        align="end"
        sideOffset={-13}
        css={{
          padding: '$2',
          marginTop: '$2 !important',
          zIndex: '$overlay',
          borderColor: '#EEEFF2',
          width: '$60',
          position: 'relative',
          boxShadow: '8px 0px 16px 0px #191F2E3D',
        }}
      >
        <Dropdown.Item
          asChild
          css={{
            px: '$md',
          }}
        >
          <Flex
            align="center"
            gap="sm"
            css={{
              borderBottom: '1px solid #EEEFF2',
              backgroundColor: '$white !important',
              cursor: 'auto',
            }}
          >
            <Text
              style={{
                fontWeight: '$medium',
                color: '#191F2E',
              }}
            >
              {t('menu.quick_acess')}
            </Text>
          </Flex>
        </Dropdown.Item>
        <Can I={ActionEnum.View} a="Cheershub">
          {process.env.NEXT_PUBLIC_CHEERSHUB_MODULES_DROPDOWN_URL && (
            <ModulesDropdownItem
              subtitle={t('menu.cheershub')}
              url={process.env.NEXT_PUBLIC_CHEERSHUB_MODULES_DROPDOWN_URL}
              label={t('menu.cheershub')}
              icon={<Icon.Cheershub color="#171717" size={16} />}
            />
          )}
        </Can>
        <Can I={ActionEnum.View} a="MyLCM">
          {process.env.NEXT_PUBLIC_LCM_MODULES_DROPDOWN_URL && (
            <ModulesDropdownItem
              subtitle={t('menu.lcm')}
              url={process.env.NEXT_PUBLIC_LCM_MODULES_DROPDOWN_URL}
              label={t('menu.my_lcm')}
              icon={<Icon.Past color="#171717" size={16} />}
            />
          )}
        </Can>
        <Can I={ActionEnum.View} a="Compensation">
          <ModulesDropdownItem
            subtitle={t('menu.compensations')}
            url={process.env.NEXT_PUBLIC_REWARDS_MODULES_DROPDOWN_URL}
            label={t('menu.my_compensation')}
            icon={<Icon.MoneyBag color="#171717" size={16} />}
          />
        </Can>
        {process.env.NEXT_PUBLIC_NORTHSTAR_MODULES_DROPDOWN_URL && (
          <ModulesDropdownItem
            subtitle={t('menu.northstar')}
            url={process.env.NEXT_PUBLIC_NORTHSTAR_MODULES_DROPDOWN_URL}
            label={t('menu.my_targets')}
            icon={<Icon.Target color="#171717" size={16} />}
          />
        )}
      </Dropdown.Content>
    </Dropdown>
  );
}
