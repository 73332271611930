import { Box, css, Dropdown, styled } from '@ghq-abi/design-system';

const userBoxStyles = css({});

const arrowStyles = css({
  width: 0,
  height: 0,
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderTop: '6px solid $gray550',
});

export const StyledHeaderBox = styled(Box, {
  width: 32,
  height: 32,
  bg: '#eceef0',
  borderRadius: '$circle',
});

const userContextStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$sm',
  backgroundColor: 'transparent',
  textAlign: 'left',
  fontSize: '$md',
  position: 'relative',
  justifyContent: 'space-between',
  border: 'none',
  borderRadius: '$default',
  p: '$sm',
  '@lg': {
    gap: '$sm',
    minWidth: 50,
  },
});

export const StyledBox = styled(Box, {
  display: 'flex',
  flexFlow: 'column nowrap',
  gap: '$1',
});

export const StyledContent = styled(Dropdown.Content, {
  width: '100%',
  zIndex: '$overlay',
  padding: '$2',
  gap: '$2',
  borderRadius: '$2',
  border: '1px solid #CACDD5',
  backgroundColor: '$white',
  boxShadow: '8px 0px 16px 0px #191F2E3D',
});

export const StyledAvatarItem = styled(Dropdown.Item, {
  cursor: 'auto',
  width: '264px',
  gap: '$4',
  borderRadius: '$2',
  marginTop: '0px !important',
  backgroundColor: '#F2DC39 !important',
  transition: 'none',
});

export const StyledDropdownItem = styled(Dropdown.Item, {
  gap: '$sm',
  p: '$5',
  mt: '$2',
  height: '$12',
  borderRadius: '$1',
  '&:hover': {
    backgroundColor: '#ededef !important',
  },
});

export const StyledUserBox = styled(Box, userBoxStyles);
export const StyledArrow = styled('span', arrowStyles);
export const StyledContext = styled('button', userContextStyles);
